<template>
  <div>
    <a-row :gutter="24">
      <a-col :md="24">
        <a-card :bordered="false">
          <!-- 查询区域 -->
          <div class="table-page-search-top">
            <div class="table-page-search-wrapper table-page-search-header">
              <!-- 搜索区域 -->
              <a-form layout="inline" @keyup.enter.native="searchQuery">
                <a-row :gutter="40">
 
                  <a-col :span="6">
                    <a-form-item
                      label="账户地址"
                      :labelCol="{ span: 5 }"
                      :wrapperCol="{ span: 18, offset: 1 }"
                    >
                      <a-input
                        placeholder="账户地址"
                        v-model="queryParam.address"
                        allow-clear
                        @input="inputSearchQuery"
                      ></a-input>
                    </a-form-item>
                  </a-col>
                  <!-- <a-col :span="6">
                    <a-form-item
                      label="APIKEY"
                      :labelCol="{ span: 5 }"
                      :wrapperCol="{ span: 18, offset: 1 }"
                    >
                      <a-input
                        placeholder="APIKEY"
                        v-model="queryParam.apikey"
                        allow-clear
                        @input="inputSearchQuery"
                      ></a-input>
                    </a-form-item>
                  </a-col> -->
                  <a-col :span="6">
                    <a-form-item
                      label="策略ID"
                      :labelCol="{ span: 5 }"
                      :wrapperCol="{ span: 18, offset: 1 }"
                    >
                      <a-input
                        placeholder="策略ID"
                        v-model="queryParam.id"
                        allow-clear
                        @input="inputSearchQuery"
                      ></a-input>
                    </a-form-item>
                  </a-col>
                 
                 
                  <a-col :span="6">
                    <a-form-item
                      label="功能方向"
                      :labelCol="{ span: 5 }"
                      :wrapperCol="{ span: 18, offset: 1 }"
                    >
                      <a-select
                        v-model="queryParam.function"
                        allowClear
                        @change="searchQuery"
                        placeholder="功能方向"
                      >
                        <a-select-option value="mark_market">做市</a-select-option>
                        <a-select-option value="flush_kline">跑量</a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :span="6">
                    <a-form-item
                      label="合约交易对"
                      :labelCol="{ span: 5 }"
                      :wrapperCol="{ span: 18, offset: 1 }"
                    >
                      <a-input
                        placeholder="合约交易对"
                        v-model="queryParam.symbol"
                        allow-clear
                        @input="inputSearchQuery"
                      ></a-input>
                    </a-form-item>
                  </a-col>
                  
                </a-row>
              </a-form>
            </div>
            <div class="table-page-search" style="margin-top: 25px">
              <div class="button-onditions">
                <a-button icon="search" type="primary" @click="searchQuery"
                  >查詢</a-button
                >
               
                <a-button icon="sync" style="margin-top: 5px" type="primary" @click="searchReset">重置</a-button>
              </div>
            </div>
          </div>
          <!-- 操作按钮区域 -->
          <div class="table-operator" style="margin-top: 5px">
            <!-- <a-button
              type="primary"
              icon="download"
              @click="handleExportXls('賬號清單')"
              :loading="exportLoading"
              id="export"
              >導出</a-button
            > -->
          </div>
          <!-- table区域-begin -->
          <div style="margin-top: 25px">
            <a-table
              ref="table"
              size="middle"
              bordered
              rowKey="id"
              :columns="columns"
              :dataSource="dataSource"
              :pagination="ipagination"
              :loading="loading"
              @change="handleTableChange"
              class="table_info"
              :scroll="{ x: scrollX }"
            >
              <span slot="action" slot-scope="text, record">
                <a-tag
                  color="#87d068"
                  v-if="btnEnableList.indexOf(1) > -1"
                  @click="handleEdit(record)"
                  >编辑</a-tag
                >
                <!-- <a-popconfirm
                  v-if="btnEnableList.indexOf(1) > -1"
                  title="确定删除吗?"
                  @confirm="() => handleDelete(record.id)"
                >
                  <a-tag color="#f50">删除</a-tag>
                </a-popconfirm> -->
              </span>
            </a-table>
          </div>
          <!-- table区域-end -->
          <strategy-account-modal ref="modalForm" @ok="modalFormOk"></strategy-account-modal>

        </a-card>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import { JeecgListMixin } from "@/mixins/JeecgListMixin";
import { AdapterTableMixin } from "@/mixins/AdapterTableMixin";
import { downFile, postAction } from "@/api/manage";
import StrategyAccountModal from "./StrategyAccountModal";

import moment from "moment";

export default {
  name: "accountList",
  mixins: [JeecgListMixin, AdapterTableMixin],
  components: {StrategyAccountModal},
  data() {
    return {
      // 查询条件
      queryParam: {
        orderByType: '', // 默认不选择排序

      },
      // 员工
      staffList: [],
     
      // 表头
      columns: [
        {
          title: "#",
          dataIndex: "",
          width: 40,
          align: "center",
          customRender(t, r, index) {
            return parseInt(index) + 1;
          },
        },
        {
          title: "账户地址",
          align: "center",
          dataIndex: "address",
        },

        {
          title: "策略ID",
          align: "center",
          dataIndex: "id",
        },
        {
          title: "功能",
          align: "center",
          dataIndex: "function",
          customRender: function (t, r, index) {
            if (t == "mark_market") {
              return <a-tag color="rgb(50 184 111)">做市</a-tag>;
            } else {
              return <a-tag color="rgb(50 184 111)">量化</a-tag>;
            }
          },
        },
        {
          title: "合约",
          align: "center",
          dataIndex: "symbol",
          
        },
        {
          title: "运行状态情况",
          align: "center",
          dataIndex: "status",
        },
        {
          title: "层数",
          align: "center",
          dataIndex: "layer",
        },
        {
          title: "最小时间间隔",
          align: "center",
          dataIndex: "min_time_interval",
        },
        {
          title: "最大时间间隔",
          align: "center",
          dataIndex: "max_time_interval",
        },
        {
          title: "最小下单量",
          align: "center",
          dataIndex: "min_place_order_amount",
        },
        {
          title: "最大下单量",
          align: "center",
          dataIndex: "max_place_order_amount",
        },
        {
          title: "买卖最小间距",
          align: "center",
          dataIndex: "min_buy1_sell1_diff",
        },
        {
          title: "买卖最大间距",
          align: "center",
          dataIndex: "max_buy1_sell1_diff",
        },
        {
          title: "其他最小间距",
          align: "center",
          dataIndex: "min_other_diff",
        },
        {
          title: "其他最大间距",
          align: "center",
          dataIndex: "max_other_diff",
        },
       
        {
          title: "操作",
          dataIndex: "action",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      url: {
        list: "/account/page",
        exportXlsUrl: "/account/export",
      },
      dateFormat: "YYYY-MM-DD",
      exportLoading: false,
    
    };
  },
  computed: {},
  created() {},
  methods: {
    handleEdit(record) {
      this.$refs.modalForm.edit(record);
      this.$refs.modalForm.title = "编辑 "+record.symbol +" "+record.id;
      if (this.btnEnableList.indexOf(1) === -1) {
        this.$refs.modalForm.isReadOnly = true;
      }
    },
    handleExportXls(fileName, url) {
      if (this.exportLoading) {
        this.$message.warning("其他文件正在导出，请稍后再试！");
        return false;
      }
      let exportXlsUrl;
      if (url) {
        exportXlsUrl = url;
      } else {
        exportXlsUrl = this.url.exportXlsUrl;
      }

      this.exportLoading = true;
      if (!fileName || typeof fileName != "string") {
        fileName = "导出文件";
      }
      let param = { ...this.queryParam };
      if (this.selectedRowKeys && this.selectedRowKeys.length > 0) {
        param["ids"] = this.selectedRowKeys.join(",");
      }
      downFile(exportXlsUrl, param).then((data) => {
        if (!data) {
          this.$message.warning("文件下载失败");
          this.exportLoading = false;
          return;
        }
        if (typeof window.navigator.msSaveBlob !== "undefined") {
          window.navigator.msSaveBlob(
            new Blob([data], { type: "application/vnd.ms-excel" }),
            fileName + ".xls"
          );
          this.exportLoading = false;
        } else {
          let url = window.URL.createObjectURL(
            new Blob([data], { type: "application/vnd.ms-excel" })
          );
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.setAttribute("download", fileName + ".xls");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link); //下载完成移除元素
          window.URL.revokeObjectURL(url); //释放掉blob对象
          this.exportLoading = false;
        }
      });
    },
  },
  watch: {
    
  },
};
</script>
<style scoped>
@import "~@assets/less/common.less";
@import "~@assets/less/button.less";
@import "~@assets/less/TaskProcess.less";
.table_info ::v-deep .ant-table-tbody > tr > td {
  padding: 5px 8px !important;
  overflow-wrap: break-word;
  font-weight: 500;
}
</style>
